@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Taprom&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Taprom&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unna:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .hoverEffect {
    @import url(duration-300 cursor-pointer);
  }
  .headerSedenavLi {
    @import url(hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white);
  }
}

.new {
  font-family: "Pacifico", cursive !important;
  font-weight: 400;
  font-style: normal;
}

.new1 {
  font-family: "Taprom", system-ui !important;
  font-weight: 400;
  font-style: normal;
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: black;
  font-weight: 600;
  text-decoration: 1px underline black;
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
/* ============= NavLink active End here ================ */

*::-webkit-scrollbar {
  display: none;
}

*::selection {
  color: white;
  background-color: black;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 15s linear infinite;
}

* {
  font-family: "Lato", serif;
  font-weight: 400;
  font-style: normal;
}
